import { createSignal } from "solid-js";

import ListTile from "@repo/ui/info/ListTile";
import Autocomplete from "@repo/ui/inputs/Autocomplete";
import FullName from "@repo/utils/FullName";
import PhoneNumber from "@repo/utils/PhoneNumber";
import AppRouter from "@repo/utils-solid/AppRouter";

import { useUsers } from "~/state/features/users";

import Status from "./Status";

declare namespace UserSearch {
  type Props = {};
}

function UserSearch({}: D<UserSearch.Props>) {
  const [users] = useUsers();
  const [query, setQuery] = createSignal("");

  return (
    <Autocomplete
      label="Search users"
      suggestions={
        users.query(query())?.map(({ id, pii, active }) => ({
          value: id,
          content: (
            <ListTile
              class="m-0"
              align="center"
              leading={
                <Status color={active ? "var(--success)" : "var(--error"} />
              }
              title={FullName.format(pii.name)}
              subtitle={
                <>
                  <span> {pii.email}</span>
                  <span class="inline-block ml-1.5">
                     {PhoneNumber.format(pii.phone)}
                  </span>
                </>
              }
            />
          ),
        })) || []
      }
      onApplySuggestion={(suggestion) => {
        AppRouter.push(`/users/${suggestion.value}`);
      }}
      onInput={(e) => {
        setQuery(e.currentTarget.value);
      }}
    />
  );
}

export default UserSearch;
