import { createMemo, createSignal, Index, onCleanup, onMount } from "solid-js";
import { JSX } from "solid-js";

import createId from "@repo/signals/createId";
import Icon from "@repo/utils/Icon";
import UFunction from "@repo/utils/UFunction";
import UNumber from "@repo/utils/UNumber";
import UObject from "@repo/utils/UObject";
import UString from "@repo/utils/UString";
import UEventTarget from "@repo/utils-client/UEventTarget";
import UKeyboardEvent from "@repo/utils-client/UKeyboardEvent";

import ContainerButton from "../buttons/ContainerButton";
import Card from "../containers/Card";
import FloatingWithAnchor from "../floating/FloatingWithAnchor";

import TextInput from "./TextInput";

import styles from "./Autocomplete.module.scss";

declare namespace Autocomplete {
  type Props = UObject.Override<
    TextInput.Props,
    {
      suggestions: Suggestion[];
      onApplySuggestion(suggestion: Suggestion): void;
      icon?: Icon;
      ref?: Extract<TextInput.Props["ref"], UFunction.Any>;
    }
  >;
  type Suggestion = {
    value: string;
  } & (
    | {
        string?: string;
        matchedSubstrings: UNumber.Bounds[];
      }
    | {
        content: JSX.Element;
      }
  );
}

function Autocomplete({
  suggestions: _suggestions,
  onApplySuggestion,
  icon,
  id = createId(),
  ref,
  ...props
}: D<Autocomplete.Props>) {
  const suggestions = createMemo(() => _suggestions);
  const [_showSuggestions, setShowSuggestions] = createSignal(false);
  const showSuggestions = () => _showSuggestions() && suggestions().length > 0;
  const [highlight, setHighlight] = createSignal(-1);

  let inputRef: HTMLLabelElement;
  let suggestionsRef: HTMLDivElement;

  function applySuggestion(suggestion: Autocomplete.Suggestion) {
    setShowSuggestions(false);
    onApplySuggestion(suggestion);
  }

  onMount(() => {
    // Adding event listeners instead of props allows unrelated props to be passed externally
    const input = inputRef.querySelector("input")!;
    input.addEventListener("focus", () => {
      setShowSuggestions(true);
    });
    input.addEventListener("input", () => {
      setShowSuggestions(true);
    });
    input.addEventListener("keydown", (e) => {
      if (e.key === "Enter") {
        if (!showSuggestions()) return;

        e.preventDefault();
        if (UNumber.Bounds.includes([0, suggestions().length], highlight()))
          applySuggestion(suggestions()[highlight()]!);
        return;
      }

      if (!suggestions().length) return;

      // NOTE: As of 06/01/2024, 1Password extension captures ArrowDown events
      // after the first one, must disable the extension for the down keyboard
      // shortcut to work
      const direction = -UKeyboardEvent.arrowDirection(e);
      if (direction) {
        if (showSuggestions()) e.preventDefault();
        setHighlight(
          // If nothing is highlighted (-1) and direction is -1, we want to set
          // highlight to suggestions.length - 1 instead of - 2
          (Math.max(highlight() + direction, -1) + suggestions().length) %
            suggestions().length,
        );
      }
    });

    onCleanup(
      UEventTarget.wrap(document).on("click", (e) => {
        if (!(e.target instanceof Node)) return;
        if (inputRef.contains(e.target) || suggestionsRef?.contains(e.target))
          return;
        setShowSuggestions(false);
      }).off,
    );
  });

  return (
    <FloatingWithAnchor
      anchor={
        <TextInput
          {...props}
          ref={(element) => {
            inputRef = element;
            ref?.(element);
          }}
          id={id}
        />
      }
      show={showSuggestions()}
    >
      <label for={id}>
        <Card
          ref={suggestionsRef!}
          outline
          class={styles.suggestions}
          onMouseLeave={() => {
            setHighlight(-1);
          }}
        >
          <Index each={suggestions()}>
            {(suggestion, i) => (
              <ContainerButton
                class={`${styles.suggestion} row-center ${highlight() === i && styles.highlight}`}
                onClick={() => {
                  setShowSuggestions(false);
                  applySuggestion(suggestion());
                }}
                onMouseEnter={() => {
                  setHighlight(i);
                }}
              >
                {icon && (
                  <span class="text:large color:primary-weak mr-1">{icon}</span>
                )}
                <div>
                  {(() => {
                    const s = suggestion();

                    return "content" in s
                      ? s.content
                      : UString.splitAt(
                          s.string || s.value,
                          s.matchedSubstrings.flat(),
                        ).map((substr, i) =>
                          i % 2 ? <b>{substr}</b> : substr,
                        );
                  })()}
                </div>
              </ContainerButton>
            )}
          </Index>
        </Card>
      </label>
    </FloatingWithAnchor>
  );
}

export default Autocomplete;
